<template>
  <v-dialog
    v-model="dialogoImportacaoDePeca"
    max-width="90%"
    scrollable
    @keydown.esc="sair()"
  >
    <v-card>
      <v-card-title class="subtitle-1 cyan white--text">
        Validação de Importação de peça
      </v-card-title>
      <v-card-text class="mt-4">
        <div class="validacaoImportacaoDePeca">
          <v-container class="fill-height fill-width pt-10">
            <v-alert
              v-if="dadosComErrosIndexs.length > 0"
              class="warning lighten-5 black--text fill-width pl-8"
              width="10000"
              icon="warning"
              border="left"
              prominent
            >
              <v-col class="pl-8">
                <v-row class="pb-5">
                  <h1 class="text-h3">Atenção! As peças abaixo exigem ação</h1>
                </v-row>
                <v-card
                  class="ma-5 pa-5"
                  elevation="6"
                  v-for="key in dadosComErrosIndexs"
                  :key="key"
                >
                  <v-col>
                    <v-row>
                      <h2>{{ key }}</h2>
                    </v-row>

                    <v-row
                      v-if="dadosComErros[key].dadoJaExistente"
                      class="fill-width"
                    >
                      <v-card
                        flat
                        class="fill-width mb-5"
                        elevation="6"
                        width="100%"
                      >
                        <v-col>
                          <v-row class="error black--text">
                            <v-icon class="ml-2 black--text" left
                              >warning</v-icon
                            >
                            <h3>Peça já cadastrada</h3>
                          </v-row>
                        </v-col>
                      </v-card>
                    </v-row>

                    <v-row
                      class="fill-width"
                      v-if="
                        !dadosComErros[key].dadoJaExistente &&
                        dadosComErros[key].dadosDuplicados.length > 1
                      "
                    >
                      <v-card
                        flat
                        class="fill-width mb-5"
                        elevation="6"
                        width="100%"
                      >
                        <v-col>
                          <v-row class="error black--text">
                            <v-icon class="ml-2 black--text" left
                              >warning</v-icon
                            >
                            <h3>
                              Peça Duplicada, escolha qual Observação irá manter
                            </h3>
                          </v-row>
                          <v-row
                            v-for="linha in dadosComErros[key].dadosDuplicados"
                            :key="linha"
                          >
                            <v-layout wrap>
                              <v-flex
                                align-self-center
                                xs12
                                sm5
                                md3
                                lg2
                                class="py-2 pl-2"
                              >
                                <v-btn
                                  color="primary"
                                  @click="
                                    dadosComErros[key].dadosDuplicados = [
                                      linha,
                                    ];
                                    checkForError(key);
                                  "
                                  >Escolher</v-btn
                                >
                              </v-flex>
                              <v-flex align-self-center xs12 sm7 md9 lg10>{{
                                dados[linha]["Observação"]
                              }}</v-flex>
                              <v-flex xs12>
                                <v-divider></v-divider>
                              </v-flex>
                            </v-layout>
                          </v-row>
                        </v-col>
                      </v-card>
                    </v-row>

                    <v-row
                      v-if="
                        !dadosComErros[key].dadoJaExistente &&
                        dadosComErros[key].faltandoImagemPositivacao
                      "
                    >
                      <v-card
                        flat
                        class="fill-width mb-5"
                        elevation="6"
                        width="100%"
                      >
                        <v-col>
                          <v-row class="error black--text">
                            <v-icon class="ml-2 black--text" left
                              >warning</v-icon
                            >
                            <h3>
                              Adicione imagem de Positivação ou opte por deixar
                              em branco
                            </h3>
                          </v-row>

                          <v-row class="px-3">
                            <v-file-input
                              prepend-icon="mdi-file-image"
                              accept="image/*"
                              counter
                              clearable
                              small-chips
                              label="Adicionar Imagem Positivação"
                              @change="ChangeImagemPositivacao(key, $event)"
                            ></v-file-input>
                            <v-btn
                              class="mt-4 ml-3"
                              color="primary"
                              @click="ChangeImagemPositivacaoBlank(key, '')"
                              >Deixar sem imagem</v-btn
                            >
                          </v-row>
                        </v-col>
                      </v-card>
                    </v-row>

                    <v-row
                      v-if="
                        !dadosComErros[key].dadoJaExistente &&
                        dadosComErros[key].faltandoImagem3D
                      "
                    >
                      <v-card
                        flat
                        class="fill-width mb-5"
                        elevation="6"
                        width="100%"
                      >
                        <v-col>
                          <v-row class="error black--text">
                            <v-icon class="ml-2 black--text" left
                              >warning</v-icon
                            >
                            <h3>Adicione imagem 3D</h3>
                          </v-row>

                          <v-row class="px-3">
                            <v-file-input
                              prepend-icon="mdi-file-image"
                              accept="image/*"
                              counter
                              clearable
                              small-chips
                              label="Adicionar Imagem 3D"
                              @change="ChangeImagem3D(key, $event)"
                            ></v-file-input>
                          </v-row>
                        </v-col>
                      </v-card>
                    </v-row>

                    <v-row>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="error darken-4"
                        @click="
                          dadosComErrosIndexs = dadosComErrosIndexs.filter(
                            (val) => val != key
                          )
                        "
                        >Descartar</v-btn
                      >
                    </v-row>
                  </v-col>
                </v-card>
              </v-col>
            </v-alert>
            <v-col>
              <v-row>Dados que serão importados</v-row>
            </v-col>
            <v-container>
              <v-card
                flat
                v-for="(item, index) in dadosSemErros.map((val) => dados[val])"
                :key="index"
              >
                <v-layout wrap class="pa-3">
                  <v-flex xs12 sm4 lg2>
                    <div class="caption grey--text">Nome Peça Admin</div>
                    <div>{{ item["Nome da Peça Admin"] }}</div>
                  </v-flex>
                  <v-flex xs12 sm4 lg2>
                    <div class="caption grey--text">Nome Peça App</div>
                    <div>{{ item["Nome da Peça App"] }}</div>
                  </v-flex>
                  <v-flex xs12 sm4 lg2>
                    <div class="caption grey--text mb-2">imagem 3D</div>
                    <v-img
                      :src="$arquivoProtegido(item.imagem3D_URL)"
                      lazy-src="/images/not-found.png"
                      class="white lighten-5 mb-2"
                      max-height="50"
                      max-width="50"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-flex>
                  <v-flex xs12 sm4 lg2>
                    <div class="caption grey--text mb-2">
                      imagem Positivacao
                    </div>
                    <v-img
                      :src="$arquivoProtegido(item.imagemPositivacao_URL)"
                      lazy-src="/images/not-found.png"
                      class="white lighten-5 mb-2"
                      max-height="50"
                      max-width="50"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-flex>
                  <v-flex xs12 sm12 lg4>
                    <div class="caption grey--text">Observação</div>
                    <div>{{ item["Observação"] }}</div>
                  </v-flex>

                  <!-- <v-flex xs12 sm12 lg2>
              <div class="caption grey--text">Ações</div>
              <v-layout class="px-2">
                <v-flex class="pr-2" xs6>
                  <v-btn small color="primary" block>Editar</v-btn>
                </v-flex>
                <v-flex class="pt-2" xs6>
                  <v-btn small color="error" block>Excluir</v-btn>
                </v-flex>
              </v-layout>
            </v-flex> -->
                </v-layout>
                <v-divider
                  v-if="index != dados.length - 1"
                  :key="index"
                  :inset="false"
                ></v-divider>
              </v-card>
            </v-container>

            <v-snackbar v-model="snackbar">
              Ainda existem ações a serem tomadas
              <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false"
                  >Fechar</v-btn
                >
              </template>
            </v-snackbar>

            <v-snackbar v-model="snackbar1">
              {msg}
              <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false"
                  >Fechar</v-btn
                >
              </template>
            </v-snackbar>

            <v-overlay :value="overlay">
              <v-progress-circular indeterminate size="64">{{
                msg
              }}</v-progress-circular>
            </v-overlay>
          </v-container>
        </div>
      </v-card-text>
      <v-divider></v-divider>

      <v-spacer></v-spacer>
      <v-card-actions>
        <v-btn class="cyan" @click="voltar"
          ><v-icon>mdi-chevron-left</v-icon>Voltar</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn color="error" @click="sair">
          <v-icon>mdi-close</v-icon>Cancelar</v-btn
        >
        <v-btn class="cyan" @click="send"
          ><v-icon>mdi-chevron-right</v-icon>Enviar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import XLSX from "xlsx";
import axios from "axios";

export default {
  props: {
    dialogoImportacaoDePeca: {
      type: Boolean,
    },
  },
  data: () => ({
    dados: [],
    dadosComErros: [],
    dadosSemErros: [],
    dadosComErrosIndexs: [],
    xls: {},
    imagem3D: null,
    imagemPositivacao: null,
    snackbar: false,
    snackbar1: false,
    alerta: false,
    msg: "",
    overlay: true,
  }),
  computed: {},
  methods: {
    sair() {
      this.$emit("sairmodal", false);
    },
    readerData(rawFile, sheet) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          const selectedSheet = workbook.SheetNames[sheet];
          const worksheet = workbook.Sheets[selectedSheet];
          // const header = this.getHeaderRow(worksheet)
          const results = XLSX.utils.sheet_to_json(worksheet);
          const meta = { sheetName: selectedSheet };
          resolve({ results, meta });
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    voltar() {
      this.$store.commit("dialogostep/MudarState", { step: 1 });
    },
    async send() {
      try {
        if (this.dadosComErrosIndexs.length > 0) this.snackbar = true;
        else {
          this.overlay = true;
          this.msg = "Cadastrando...";
          const regexAll = /[^\\]*\.(\w+)$/;

          let dadosAEnviar = this.dadosSemErros.map((val) => this.dados[val]);
     
          await Promise.all(dadosAEnviar);

          const extensions = dadosAEnviar.reduce((acc, cur) => {
            if (cur.imagem3D instanceof File)
              acc.push(cur.imagem3D.name.match(regexAll)[1]);
            if (cur.imagemPositivacao instanceof File)
              acc.push(cur.imagemPositivacao.name.match(regexAll)[1]);
            return acc;
          }, []);
          
        

          await Promise.all(extensions);

          const links = (
            await axios.post(
              `${this.$SERVER_URL}adm/sync/images`,
              { fileTypes: extensions },
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods":
                    "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                  "Access-Control-Allow-Headers":
                    "Origin, Content-Type, X-Auth-Token",
                },
              }
            )
          ).data;

   
          await Promise.all(links);


          const dados = dadosAEnviar.map(async(element,index) => {

       
           if (element.imagem3D instanceof File) {

              element.imagem3D_URL_SAVE = links[index].downloadUrl;

                  await   axios.put(links[index].uploadUrl, element.imagem3D, {
                      headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods":
                          "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers":
                          "Origin, Content-Type, X-Auth-Token",
                        "Content-Type": element.imagem3D.type,
                      },
                    });
                  } else {
                    element.imagem3D_URL_SAVE = "";
                  }

           

            if (element.imagemPositivacao instanceof File) {
              
              element.imagemPositivacao_URL_SAVE = links[index].downloadUrl;
              await   axios.put(links[index].uploadUrl, element.imagemPositivacao, {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods":
                    "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                  "Access-Control-Allow-Headers":
                    "Origin, Content-Type, X-Auth-Token",
                  "Content-Type": element.imagemPositivacao.type,
                },
              });

              index++;
            } else {
              element.imagemPositivacao_URL_SAVE = "";
            }

        
            return element;
          
           
           })

       

          
          var promisse = await Promise.all(dados).then((val)=>{
              return val
            })

          
            
         const final = promisse.map((val) => (console.log(val),{
              
                  nome: val["Nome da Peça Admin"],
                  nomepecaapp: val["Nome da Peça App"],
                  observacao: val["Observação"],
                  imagem_3d: val["imagem3D_URL_SAVE"],
                  imagem_positivacao: val["imagemPositivacao_URL_SAVE"],
                  userid: localStorage.getItem("userid"),
                }));
   
        



         

      
     
          await Promise.all(final);

        
         await  axios.post(`${this.$SERVER_URL}adm/pecas`, final, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods":
                  "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Headers":
                  "Origin, Content-Type, X-Auth-Token",
              },
            })
            .then((response) => {
              this.$store.commit("importacaoPecas/reset");
              this.$store.commit("dialogostep/MudarState", { step: 1 });

              return response;
            });

         

      

       
          this.overlay = false;

          this.$router.go();
        }
      } catch (error) {
        this.alerta = true;
        this.msg = "Algum erro aconteceu, tente novamente";
        this.overlay = false;
      }
    },
    async getImageFromFile(file) {
      return new Promise((res) => {
        const fr = new FileReader();

        fr.onload = function () {
          res(fr.result);
        };
        fr.readAsDataURL(file);
      });
    },
    async computeXlsData() {
      this.overlay = true;
      this.tabela = (await this.readerData(this.xls, 0)).results;

      this.dados = this.tabela.map(async (val, index) => {
        const regex = new RegExp(
          `${val["Nome da Peça Admin"]}\\.[A-Za-z]+`,
          "i"
        );
        const imagem3D = this.imagem3D.find((imagem) =>
          imagem.name.match(regex)
        );
        const imagem3D_URL = imagem3D
          ? await this.getImageFromFile(imagem3D)
          : "";
        const imagemPositivacao = this.imagemPositivacao.find((imagem) =>
          imagem.name.match(regex)
        );
        const imagemPositivacao_URL = imagemPositivacao
          ? await this.getImageFromFile(imagemPositivacao)
          : "";
        return {
          ...val,
          imagem3D,
          imagem3D_URL,
          imagemPositivacao,
          imagemPositivacao_URL,
          index,
        };
      });
      this.dados = await Promise.all(this.dados);
      const { dadosComErros, dadosSemErros } = this.procuraPorErros(this.dados);
      this.dadosComErros = dadosComErros;
      this.dadosSemErros = dadosSemErros;
      this.dadosComErrosIndexs = Object.keys(dadosComErros);


    },
    procuraPorErros(tabela) {
      const dadosComErros = tabela.reduce((acc, cur, index) => {
        if (!acc[cur["Nome da Peça Admin"]]) {
          acc[cur["Nome da Peça Admin"]] = {
            dadosDuplicados: [index],
            dadoJaExistente: this.pecas.find(
              (val) => val.nome === cur["Nome da Peça Admin"]
            ),
            faltandoImagem3D: cur.imagem3D == null,
            faltandoImagemPositivacao: cur.imagemPositivacao == null,
          };
        } else {
          acc[cur["Nome da Peça Admin"]].dadosDuplicados = [
            ...acc[cur["Nome da Peça Admin"]].dadosDuplicados,
            index,
          ];
        }
        return acc;
      }, {});
      const keys = Object.keys(dadosComErros);
      const dadosSemErros = [];
      keys.forEach((key) => {
        if (
          dadosComErros[key].dadosDuplicados.length === 1 &&
          !dadosComErros[key].faltandoImagem3D &&
          !dadosComErros[key].faltandoImagemPositivacao &&
          !dadosComErros[key].dadoJaExistente
        ) {
          dadosSemErros.push(dadosComErros[key].dadosDuplicados[0]);
          delete dadosComErros[key];
        }
      });
      return { dadosComErros, dadosSemErros };
    },
    checkForError(key) {
  
      if (
        this.dadosComErros[key].dadosDuplicados.length === 1 &&
        !this.dadosComErros[key].faltandoImagem3D &&
        !this.dadosComErros[key].faltandoImagemPositivacao &&
        !this.dadosComErros[key].dadoJaExistente
      ) {
    
        this.dadosSemErros.push(this.dadosComErros[key].dadosDuplicados[0]);
        this.dadosComErrosIndexs = this.dadosComErrosIndexs.filter(
          (val) => val != key
        );

      }
    },
    async ChangeImagemPositivacao(key, event) {
      if (event instanceof File) {
        this.dadosComErros[key].faltandoImagemPositivacao = false;
        const urlimage = await this.getImageFromFile(event);
        this.dadosComErros[key].dadosDuplicados.forEach((val) => {
          this.dados[val].imagemPositivacao = event;
          this.dados[val].imagemPositivacao_URL = urlimage;
        });
        this.checkForError(key);
      }
    },
    async ChangeImagemPositivacaoBlank(key, event) {
      this.dadosComErros[key].faltandoImagemPositivacao = false;
      const urlimage = "/images/not-found.png";
      this.dadosComErros[key].dadosDuplicados.forEach((val) => {
        this.dados[val].imagemPositivacao = event;
        this.dados[val].imagemPositivacao_URL = urlimage;
      });
      this.checkForError(key);
    },

    async ChangeImagem3D(key, event) {
      if (event instanceof File) {
        this.dadosComErros[key].faltandoImagem3D = false;
        const urlimage = await this.getImageFromFile(event);
        this.dadosComErros[key].dadosDuplicados.forEach((val) => {
          this.dados[val].imagem3D = event;
          this.dados[val].imagem3D_URL = urlimage;
        });
      
        this.checkForError(key);
      }
    },
  },
  watch: {},
  async mounted() {
    try {
      const importacao = this.$store.state.importacaoPecas;
  

      if (Object.keys(importacao).length > 0) {
        this.xls = this.$store.state.importacaoPecas.xls;
        this.imagem3D = this.$store.state.importacaoPecas.imagem3D;
        this.imagemPositivacao =
          this.$store.state.importacaoPecas.imagemPositivacao;
        this.overlay = false;

        this.pecas = (
          await axios.get(`${this.$SERVER_URL}adm/peca`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
            },
          })
        ).data;

        this.$store.commit("importacaoPecas/pecasJaCadastradas", {
          pecas: this.pecas,
        });

        await this.computeXlsData();
        this.overlay = false;
      } else {
        this.$store.commit("dialogostep/MudarState", { step: 1 });
      }
    } catch (error) {
      alert("Aconteceu algum erro, tente novamente.");
    }
  },
};
</script>
<style>
.validacaoImportacaoDePeca .v-icon.v-alert__icon {
  color: black;
}
</style>